import React from 'react'
import { NavLink } from 'react-router-dom'
function CarCard({ img, name, people, fuelType, bodyType, price, page }) {
  return (
    <NavLink to={`/rent/${page}`} className='car-card'>
      <div className='car-card-img'>
        <img src={require(`../assets/img/cars/${img}`)} alt='' />
      </div>
      <div className='car-card-info'>
        <div className='car-card-info-name'>{name}</div>
        <div className='car-card-info-lists'>
          <div className='car-card-info-list'>
            <img
              src={require('../assets/img/svg-car/people.svg').default}
              alt=''
            />
            {people}
          </div>
          <div className='car-card-info-list'>
            <img
              src={require('../assets/img/svg-car/gas-station.svg').default}
              alt=''
            />
            {fuelType}
          </div>
          <div className='car-card-info-list'>
            <img
              src={require('../assets/img/svg-car/car.svg').default}
              alt=''
            />
            {bodyType}
          </div>
        </div>
        <div className='car-card-info-book'>
          <div className='car-card-info-book-left'>
            <div className='car-card-info-book-text'>Daily rate from</div>
            <div className='car-card-info-book-price'>${price}</div>
          </div>
          <div className='car-card-info-book-right'>
            <NavLink to={`/rent/${page}`} className='car-card-info-book-btn'>
              Book Now
            </NavLink>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export { CarCard }
