import { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { HomePage } from './pages/HomePage'
import { RentPage } from './pages/RentPage'
import { RentDetailPage } from './pages/RentDetailPage'
import { BookPage } from './pages/BookPage'
import { TermsService } from './pages/TermsService'
import { LoginPage } from './pages/LoginPage'
import { ContactPage } from './pages/ContactPage'
import { WhyWe } from './pages/WhyWe'
import { NotfoundPage } from './pages/NotfoundPage'
import { Layout } from './components/Layout'

/**
 * In this project, I decided to use scss, so that I could easily type it
 * and have additional features like file separation and the use of constants
 */
import './assets/scss/style.scss'

/**
 * The NotfoundPage works as a 404 page, if the path is incorrect then it will not load this page.
 */
function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='rent' element={<RentPage />} />
          <Route path='rent/:productId' element={<RentDetailPage />} />
          <Route path='rent/:productId/book' element={<BookPage />} />
          <Route path='terms-of-service' element={<TermsService />} />
          <Route path='contact' element={<ContactPage />} />
          <Route path='why-we' element={<WhyWe />} />

          <Route path='login' element={<LoginPage />} />
          <Route path='*' element={<NotfoundPage />} />
        </Route>
      </Routes>
    </>
  )
}

export default App
