import React from 'react'

function ContactPage() {
  return (
    <section
      className='contact'
    >
      <div className='container'>
        <div className='contact__inner'>
          <div className='contact-title'>
            <h2 className='global-title black'>Contact Us</h2>
          </div>
          <div className='contact-box'>
            <form className='contact-box-form'>
              <input
                className='custom-input'
                type='text'
                placeholder='Your Name*'
              />
              <input
                className='custom-input'
                type='text'
                placeholder='Your Email*'
              />
              <textarea
                className='custom-textarea'
                placeholder='Your Message'
              ></textarea>
              <button className='big-btn' type='submit'>
                Send A Message
              </button>
            </form>
            <div className='contact-box-img'>
              <img src={require('../assets/img/hand.jpg')} alt='search' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { ContactPage }
