import React from 'react'
import { NavLink } from 'react-router-dom'
// import { ReactComponent as Logo } from '../assets/svg/logo.svg'
import phoneCall from '../assets/img/svg/phone-call.svg'
import sharpEmail from '../assets/img/svg/sharp-email.svg'
import locationFilled from '../assets/img/svg/location-filled.svg'
function Footer() {
  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer__inner'>
          <div className='footer__inner-top'>
            <div className='footer__inner-items'>
              <div className='footer__inner-item'>
                
                <ul>
                  <li>
                    <img src={locationFilled} alt='Location' />
                    <a
                      href='https://maps.app.goo.gl/4B1zcFc7X8cQEE1B7'
                      target='_blank'
                    >
                      25566 Hc 1, Glenallen, Alaska, 99588, USA
                    </a>
                  </li>
                  <li>
                    <img src={phoneCall} alt='Phone' />
                    <a href='tel:60378427312'>+603 4784 273 12</a>
                  </li>
                  <li>
                    <img src={sharpEmail} alt='Email' />
                    <a href='mailto:rentcars@gmail.com'>rentcars@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='footer__inner-items'>
              <div className='footer__inner-item'>
                <div className='footer__inner-item-title'>Our Product</div>
                <ul>
                  <li>
                    <NavLink to='/'>SUV</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Electric Vehicles</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Truck</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Minivans</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Sport Coupe</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Convertible</NavLink>
                  </li>
                </ul>
              </div>
              <div className='footer__inner-item'>
                <div className='footer__inner-item-title'>Resources</div>
                <ul>
                  <li>
                    <NavLink to='/'>Terms of service</NavLink>
                  </li>
                  
                </ul>
              </div>
              <div className='footer__inner-item'>
                <div className='footer__inner-item-title'>About Rentcars</div>
                <ul>
                  <li>
                    <NavLink to='/'>Why choose us</NavLink>
                  </li>
                  <li>
                    <NavLink to='/'>Contact</NavLink>
                  </li>
                 
                </ul>
              </div>
            </div>
          </div>
          <div className='footer__inner-bottom'>
            <div className='footer__inner-bottom-line'></div>
            <div className='footer__inner-bottom-text'>
              Copyright 2023 ・ Rentcars, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export { Footer }
