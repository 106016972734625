import React from 'react'
function WhyWe() {
  return (
    <>
      <section className='way-we'>
        <div className='container'>
          <div className='way-we__inner'>
            <h2 className='global-title'>Why we?</h2>
            <div className='way-we-box'>
              <div className='way-we-box-items'>
                <div className='way-we-box-item'>
                  <div className='way-we-box-item-title'>
                    More bang for your buck
                  </div>
                  <p>
                    Find deals on all kinds of drives — from the everyday to the
                    extraordinary — complete with a better, more convenient
                    experience versus rental car companies. Find the perfect
                    vehicle for your budget, starting at $25 per day.
                  </p>
                </div>
                <div className='way-we-box-item'>
                  <div className='way-we-box-item-title'>Free cancellation</div>
                  <p>
                    Cancel for a full refund up to 24 hours before your trip
                    starts. Because life happens and it helps to be flexible
                    when it does.
                  </p>
                </div>
                <div className='way-we-box-item'>
                  <div className='way-we-box-item-title'>
                    Convenient delivery options
                  </div>
                  <p>
                    Get your car delivered right to you or wherever you’re
                    going. Many hosts offer delivery to custom locations or
                    popular points of interest like airports, train stations,
                    and hotels.
                  </p>
                </div>
              </div>
              <div className='way-we-box-img'>
                <img src={require('../assets/img/urus.jpg')} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='how-works mb'>
        <div className='how-works-img'>
          <img src={require('../assets/img/bg-g-class.jpg')} alt='' />
        </div>
        <div className='container'>
          <div className='how-works__inner'>
            <div className='how-works-head'>
              <div className='how-works-title'>
                <h2 className='global-title'>How it woks</h2>
              </div>
              <div className='how-works-text'>
                <p>
                  Renting a luxury car has never been easier. Our streamlined
                  process makes it simple for you to book and confirm your
                  vehicle of choice online
                </p>
              </div>
            </div>
            <div className='how-works-items'>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={require('../assets/img/svg/search-lg.svg').default}
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Browse and select
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Choose from our wide range of premium cars, select the
                      pickup and return dates and locations that suit you best.
                    </p>
                  </div>
                </div>
              </div>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={
                      require('../assets/img/svg/calendar-check.svg').default
                    }
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Book and confirm
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Book your desired car with just a few clicks and receive
                      an instant confirmation via email or SMS.
                    </p>
                  </div>
                </div>
              </div>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={require('../assets/img/svg/face-happy.svg').default}
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Enjoy your ride
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Pick up your car at the designated location and enjoy your
                      premium driving experience with our top-quality service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { WhyWe }
