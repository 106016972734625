import React from 'react'
function TermsService() {
  return (
    <div className='terms-service'>
      <div className='container'>
        <div className='terms-service__inner'>
          <div className='terms-service-title'>
            <h2 className='global-title'>Terms of service</h2>
          </div>
          <div className='terms-service-text'>
            <span>Introduction</span>
            <p>
              Introduction , provides an online car sharing platform that
              connects vehicle owners with travelers and locals seeking to book
              those vehicles. It is accessible online including and as an
              application for mobile devices. The Turo websites, blog, mobile
              applications, and associated services are collectively referred to
              as “the Services”. By accessing or using the Services, including
              by communicating with us or other Turo users, you agree to comply
              with, and be legally bound by, the provisions of these Terms of
              Service (these “Terms”), whether or not you become a registered
              user of the Services. These Terms govern your access to and use of
              the Services and constitute a binding legal agreement between you
              and Turo.
            </p>
            <p>
              These Terms, together with the cancellation
              policy, nondiscrimination policy, applicable insurance terms and
              certificates, roadside assistance terms, and the additional
              policies (together, the “Policies”) constitute the “Agreement”
              between you and Turo (each a “Party” and together, “the Parties”).
              In addition, Turo provides a Car Sharing Agreement that summarizes
              the terms of each reservation, accessible in the Services for any
              booked or previous trips and you may use it as proof of a
              reservation. Modification. Turo reserves the right, at our sole
              discretion, to modify the Services or to modify the Agreement,
              including these Terms, at any time. If we modify these Terms, we
              will post the modification on the Services. We will also update
              the “Last Revised” date at the top of these Terms. If you continue
              to access or use the Services after we have posted a modification
              or have provided you with notice of a modification, you are
              indicating that you agree to be bound by the modified terms. If
              the modified terms are not acceptable to you, your sole recourse
              is to stop using and accessing the Services and close your Turo
              Account within 30 days. If you choose to close your Turo Account,
              the previous effective version of these Terms will apply to you,
              unless you use the Services during the intervening 30 day period,
              in which case the new version of these Terms will apply to you.
            </p>
            <span>Eligibility</span>
            <p>
              The Services are intended solely for guests who meet
              our eligibility requirements in the location where the vehicle is
              booked and hosts who are 21 or older, except in the United Kingdom
              and France where we permit hosts age 18 to list eligible vehicles.
              Any use of the Services by anyone that does not meet these
              eligibility requirements is expressly prohibited.
            </p>
            <span>Registration</span>
            <p>
              To access certain features of the Services, you must sign up for
              an account with us. You can create a Account by providing us your
              first and last name, email address, mobile phone number, and
              creating a password or connecting through an account with a
              third-party site or service (including Apple, Facebook, and
              Google). When you book a vehicle as a traveler or guest (“guest”),
              you provide us with certain additional information about yourself.
              Similarly, when you list a vehicle as a vehicle owner or host
              (“host”), you provide us with certain additional information about
              yourself and your vehicle(s) (if applicable). You must provide
              accurate, current, and complete information during the
              registration, booking, and/or listing process. You must keep your
              Turo Account up to date at all times. Based on information you
              provide, Turo may impose additional requirements for you to book a
              trip (e.g., providing a deposit, adding a second form of payment,
              buying a certain level of protection plan, or other
              requirements). 
            </p>
            <span>Verification</span>
            <p>
              Where permitted, Turo has the right, but not the obligation, to
              undertake screenings, checks, and engage in processes designed to
              (1) help verify the identities or check the backgrounds of users,
              including driving history and driver’s license validity and (2)
              verify vehicle details. Turo does not endorse any vehicle, user,
              or a user’s background, or commit to undertake any specific
              screening process. Turo may in its sole discretion use third-party
              services to verify the information you provide to us and to obtain
              additional related information and corrections where applicable,
              and you hereby authorize Turo to request, receive, use, and store
              such information. Turo may permit or refuse your request to book
              or list a vehicle in its sole and absolute discretion. Turo may,
              but does not commit to, undertake efforts to ensure the safety of
              vehicles shared through the Services. We do not make any
              representations about, confirm, or endorse the safety,
              roadworthiness, or legal status of any vehicles shared via the
              Services. Rather, hosts have sole responsibility to ensure their
              vehicles are in safe and operable condition, legally registered to
              be driven on public roads, have a clean title (e.g.,
              non-salvaged/non-branded/non-washed/non-written off), not subject
              to any applicable safety recalls, and otherwise satisfy
              our vehicle eligibility requirements. Consumer report
              authorization; DVLA authorization. When you attempt to book or
              list a vehicle, or at any time after where Turo reasonably
              believes there may be an increased level of risk associated with
              your Turo Account, you hereby provide Turo with written
              instructions and authorize Turo, in accordance with the Fair
              Credit Reporting Act, applicable consumer reporting laws, or any
              similar laws to obtain your personal and/or business auto
              insurance score, credit report, and/or conduct a background check,
              including a criminal background check where permissible under
              applicable law. When booking a trip in the UK, you agree that a
              Turo service provider, GB Group (GBG) will retrieve the driver
              record information of UK license holders from the Driver and
              Vehicle Licensing Agency (DVLA). GBG will provide this information
              to Turo for the purpose of enabling a car sharing transaction. The
              DVLA will disclose to GBG, and GBG will, in turn, disclose to
              Turo, all relevant information relating to a UK license holder’s
              driver record from the computerized register maintained by the
              DVLA. This information includes personal details, driving
              entitlements, valid endorsements, and/or disqualifications (if
              applicable). This authorization will remain valid for three years
              from the date of your first booking.
            </p>
            <span>Fees</span>
            <p>
              The fees we charge for using the Services and other cost
              structures will be itemized at checkout for guests. You can verify
              the amount for your trip at checkout before you submit your trip
              request. Hosts can view earnings on the Host Hub and learn more
              about earnings breakdown here. When you provide Turo a payment
              method, you authorize Turo, or third-party service providers
              acting on behalf of Turo, to store your payment credential for
              future use in the event you owe Turo any money. You authorize Turo
              to use stored payment credentials for balances, including for Trip
              costs, host fees, and guest fees (e.g., late fees, security
              deposits, processing fees and claims costs, and related
              administrative fees). In some cases, our payment processors have
              arrangements with card networks to automatically update stored
              payment credentials whenever you receive a new card (e.g.,
              replacing an expired card or one that was reported lost or stolen)
              and we will rely on such updates to stored payment credentials for
              balances. Any use of referral travel credit is governed by the
              terms and conditions outlined here.
            </p>
            <span>Collection of fees</span>
            <p>
              Turo and its service providers will employ all legal methods
              available to collect amounts due, including the engagement of
              collection agencies or legal counsel. Turo, or the collection
              agencies we retain, may also report information about your Turo
              Account to credit bureaus. As a result, late payments, missed
              payments, or other defaults on your Turo Account may be reflected
              in your credit report. In addition to the amount due, delinquent
              accounts or chargebacks will be charged with fees and/or charges
              that are incidental to the collection of delinquent accounts or
              chargebacks including, but not limited to, collection fees,
              convenience fees, and/or other third party charges. If you wish to
              dispute the information Turo reported to a credit bureau (i.e.,
              Experian, Equifax, or TransUnion), please contact help.turo.com.
              If you wish to dispute the information a collection agency
              reported to a credit bureau regarding your Turo Account, you must
              contact the collection agency directly.
            </p>
            <span>Taxes</span>
            <p>
              In certain jurisdictions, Turo may enable the collection and
              remittance of certain taxes from or on behalf of guests or hosts
              (including, if applicable, Co-hosts), based on existing and future
              tax regulations, including marketplace facilitator or car sharing
              regulations. The amount of taxes, if any, collected and remitted
              by Turo will be visible to, and separately stated, to both guests
              and hosts (including, if applicable Co-hosts) on their respective
              trip related documents and invoices. Where Turo is facilitating
              the collection and remittance of taxes, hosts are not permitted to
              collect the same taxes on the Services in relation to their
              vehicle sharing in that jurisdiction. EU Council Directive
              2021/514 (DAC7) requires Turo to report unadjusted gross sales of
              hosts (what we call "gross earnings"), defined as transactions
              without adjustments for credits, service fees, reimbursements, or
              any other amounts. DAC7 is an annual reporting obligation and Turo
              will share your data for all income and information for the
              previous year.
            </p>
            <span>Your commitments</span>
            <p>
              You agree that you will always use your Turo Account and the
              Services in compliance with these Terms, applicable law, and any
              other policies and standards provided to you by Turo. Account
              Activity. You are, and will be solely responsible for, all
              activity that occurs through your Turo Account. Keep your Turo
              Account information, including your password, secure. You agree
              that you will not disclose your password to any third party and
              that you will take sole responsibility for any activities or
              actions under your Turo Account, whether you have authorized such
              activities or actions. You will immediately notify Turo of any
              actual or suspected unauthorized use of your Turo Account. We are
              not responsible for your failure to comply with this clause, or
              for any delay in shutting down or protecting your Turo Account
              unless you have reported unauthorized access to us.
            </p>
            <span>Content</span>
            <p>
              Turo Content and User Content License. Subject to your compliance
              with the provisions of these Terms, Turo grants you a limited,
              revocable, non-exclusive, non-transferable license, to access and
              view any Turo and/or user content to which you are permitted
              access, solely for your personal and non-commercial purposes. You
              have no right to sublicense the license rights granted in this
              section. No licenses or rights are granted to you by implication
              or otherwise under any intellectual property rights owned or
              controlled by Turo or its licensors, except for the licenses and
              rights expressly granted in these Terms.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TermsService }
