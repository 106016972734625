import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import carsData from '../data/carsData.json'
import RadioButton from '../components/RadioButton'
import { ReactComponent as ReviewStar } from '../assets/img/svg/ReviewStar.svg'
import { ReactComponent as PayPal } from '../assets/img/svg/PayPal.svg'
import { ReactComponent as Bitcoin } from '../assets/img/svg/Bitcoin.svg'
import { ReactComponent as SecuritySafety } from '../assets/img/svg/security-safety.svg'
function BookPage() {
  const { productId } = useParams()
  const thisCar = carsData.find((prod) => String(prod.id) === productId)

  const [payment, setPayment] = useState(null)

  const handleChangeRadioPayment = (event) => {
    setPayment(event.target.value)
  }
  const isCheckedPayment = (value) => payment === value

  return (
    <div className='book'>
      <div className='container'>
        <div className='book__inner'>
          <div className='book-form'>
            <form className='payment__form'>
              <div className='payment__form-con'>
                <div className='payment__form-con-head'>
                  <div className='payment__form-con-head-text'>
                    <div className='payment__form-con-title'>Billing Info</div>
                    <div className='payment__form-con-text'>
                      Please enter your address information
                    </div>
                  </div>
                  <div className='payment__form-con-head-step'>Step 1 of 4</div>
                </div>

                <div className='payment__form-con-box'>
                  <div className='box input-con'>
                    <label>Name</label>
                    <input type='text' placeholder='Your name' required />
                  </div>
                  <div className='box input-con'>
                    <label>Phone Number</label>
                    <input type='text' placeholder='Phone number' required />
                  </div>
                  <div className='box input-con'>
                    <label>Address</label>
                    <input type='text' placeholder='Address' required />
                  </div>
                  <div className='box input-con'>
                    <label>Town / City</label>
                    <input type='text' placeholder='Town or city' required />
                  </div>
                </div>
              </div>
              <div className='payment__form-con'>
                <div className='payment__form-con-head'>
                  <div className='payment__form-con-head-text'>
                    <div className='payment__form-con-title'>Rental Info</div>
                    <div className='payment__form-con-text'>
                      Please select your rental date
                    </div>
                  </div>
                  <div className='payment__form-con-head-step'>Step 2 of 4</div>
                </div>
                <div className='payment__form-con-card-head '>
                  <div className='payment__form-con-card-head-left'>
                    <img
                      src={require('../assets/img/svg/mark.svg').default}
                      alt='visa mastercard'
                    />
                    Pick - Up
                  </div>
                </div>
                <div className='payment__form-con-box'>
                  <div className='box input-con'>
                    <label>Locations</label>
                    <select name='select'>
                      <option value disabled>
                        Select your city
                      </option>
                      <option value='value1'>Select your city</option>
                    </select>
                  </div>
                  <div className='box input-con'>
                    <label>Date</label>
                    <select name='select'>
                      <option value disabled>
                        Select your date
                      </option>
                      <option value='value1'>Select your date</option>
                    </select>
                  </div>
                  <div className='box input-con'>
                    <label>Time</label>
                    <select name='select'>
                      <option value disabled>
                        Select your time
                      </option>
                      <option value='value1'>Select your time</option>
                    </select>
                  </div>
                </div>
                <div className='payment__form-con-card-head solo'>
                  <div className='payment__form-con-card-head-left'>
                    <img
                      src={require('../assets/img/svg/mark.svg').default}
                      alt='visa mastercard'
                    />
                    Drop - Off
                  </div>
                </div>
                <div className='payment__form-con-box'>
                  <div className='box input-con'>
                    <label>Locations</label>
                    <select name='select'>
                      <option value disabled>
                        Select your city
                      </option>
                      <option value='value1'>Select your city</option>
                    </select>
                  </div>
                  <div className='box input-con'>
                    <label>Date</label>
                    <select name='select'>
                      <option value disabled>
                        Select your date
                      </option>
                      <option value='value1'>Select your date</option>
                    </select>
                  </div>
                  <div className='box input-con'>
                    <label>Time</label>
                    <select name='select'>
                      <option value disabled>
                        Select your time
                      </option>
                      <option value='value1'>Select your time</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='payment__form-con'>
                <div className='payment__form-con-head'>
                  <div className='payment__form-con-head-text'>
                    <div className='payment__form-con-title'>
                      Payment Method
                    </div>
                    <div className='payment__form-con-text'>
                      Please enter your payment method
                    </div>
                  </div>
                  <div className='payment__form-con-head-step'>Step 3 of 4</div>
                </div>

                <div className='payment__form-con-card'>
                  <div className='payment__form-con-card-head'>
                    <div className='payment__form-con-card-head-left'>
                      <img
                        src={require('../assets/img/svg/mark.svg').default}
                        alt='visa mastercard'
                      />
                      Credit Card
                    </div>
                    <div className='payment__form-con-card-head-right'>
                      <img
                        src={
                          require('../assets/img/svg/visa-mastercard.svg')
                            .default
                        }
                        alt='visa mastercard'
                      />
                    </div>
                  </div>
                  <div className='payment__form-con-box'>
                    <div className='box input-con'>
                      <label>Card Number</label>
                      <input
                        className='credit-card-number'
                        type='text'
                        name='number'
                        inputMode='numeric'
                        placeholder='Card number'
                        required
                      />
                      <span className='help-info'></span>
                    </div>
                    <div className='box input-con'>
                      <label>Expration Date</label>
                      <input
                        className='expiration-month-and-year'
                        type='text'
                        name='expiration-month-and-year'
                        placeholder='MM / YY'
                        required
                      />
                      <span className='help-info'></span>
                    </div>
                    <div className='box input-con'>
                      <label>Card Holder</label>
                      <input
                        className='billing-address-name'
                        type='text'
                        name='name'
                        placeholder='Card holder'
                        required
                      />
                      <span className='help-info'></span>
                    </div>
                    <div className='box input-con'>
                      <label>CVC</label>
                      <input
                        className='security-code'
                        inputMode='numeric'
                        type='text'
                        name='security-code'
                        placeholder='CVC'
                        required
                      />
                      <span className='help-info'></span>
                    </div>
                  </div>
                </div>
                <div className='payment__form-con-radio'>
                  <RadioButton
                    id='PayPal'
                    name='PayPal'
                    value='PayPal'
                    text='PayPal'
                    onChange={handleChangeRadioPayment}
                    checked={isCheckedPayment('PayPal')}
                    custom={<PayPal />}
                  />
                  <RadioButton
                    id='Bitcoin'
                    name='Bitcoin'
                    value='Bitcoin'
                    text='Bitcoin'
                    onChange={handleChangeRadioPayment}
                    checked={isCheckedPayment('Bitcoin')}
                    custom={<Bitcoin />}
                  />
                </div>
              </div>
              <div className='payment__form-con'>
                <div className='payment__form-con-head'>
                  <div className='payment__form-con-head-text'>
                    <div className='payment__form-con-title'>Confirmation</div>
                    <div className='payment__form-con-text'>
                      We are getting to the end. Just few clicks and your rental
                      is ready!
                    </div>
                  </div>
                  <div className='payment__form-con-head-step'>Step 4 of 4</div>
                </div>
                <div className='payment__form-con-checkbox'>
                  <div className='payment__form-con-checkbox-itme'>
                    <div className='checkbox-item'>
                      <input
                        className='checkbox-radio'
                        id='checkbox1'
                        type='checkbox'
                      />
                      <label htmlFor='checkbox1'>
                        I agree with sending an Marketing and newsletter emails.
                        No spam, promissed!
                      </label>
                    </div>
                  </div>
                  <div className='payment__form-con-checkbox-itme'>
                    <div className='checkbox-item'>
                      <input
                        className='checkbox-radio'
                        id='checkbox2'
                        type='checkbox'
                        defaultChecked
                      />
                      <label htmlFor='checkbox2'>
                        I agree with our terms and conditions and privacy
                        policy.
                      </label>
                    </div>
                  </div>
                </div>
                <button type='submit' className='payment__form-con-btn'>
                 Rent Now
                </button>
                <div className='payment__form-con-safe'>
                  <SecuritySafety />
                  <div className='payment__form-con-title'>Confirmation</div>
                  <div className='payment__form-con-text'>
                    We are getting to the end. Just few clicks and your rental
                    is ready!
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className='book-rental'>
            <div className='payment__form-con-title'>Rental Summary</div>
            <div className='payment__form-con-text'>
              Prices may change depending on the length of the rental and the
              price of your rental car.
            </div>
            <div className='book-rental-car'>
              <div className='book-rental-car-img'>
                <img
                  src={require(`../assets/img/cars/${thisCar.img}`)}
                  alt={thisCar.name}
                />
              </div>
              <div className='book-rental-car-info'>
                <div className='book-rental-car-info-name'>{thisCar.name}</div>
                <div className='book-rental-car-info-star'>
                  <ReviewStar />
                  440+ Reviewer
                </div>
                <div className='book-rental-car-info-lists'>
                  <div className='book-rental-car-info-list'>
                    <img
                      src={require('../assets/img/svg-car/people.svg').default}
                      alt=''
                    />
                    {thisCar.people}
                  </div>
                  <div className='book-rental-car-info-list'>
                    <img
                      src={
                        require('../assets/img/svg-car/gas-station.svg').default
                      }
                      alt=''
                    />
                    {thisCar.fuelType}
                  </div>
                  <div className='book-rental-car-info-list'>
                    <img
                      src={require('../assets/img/svg-car/car.svg').default}
                      alt=''
                    />
                    {thisCar.bodyType}
                  </div>
                </div>
              </div>
            </div>
            <div className='book-rental-subtotal'>
              <div className='book-rental-subtotal-title'>Subtotal</div>
              <div className='book-rental-subtotal-price'>${thisCar.price}</div>
            </div>
            <div className='book-rental-subtotal'>
              <div className='book-rental-subtotal-title'>Tax</div>
              <div className='book-rental-subtotal-price'>$16.64</div>
            </div>
            <div className='book-rental-input'>
              <div className='box input-con'>
                <input type='text' placeholder='Address Line 2' required='' />
                <button>Apply now</button>
              </div>
            </div>
            <div className='book-rental-total'>
              <div className='book-rental-total-text'>
                <div className='payment__form-con-title'>
                  Total Rental Price
                </div>
                <div className='payment__form-con-text'>
                  Overall price and includes rental discount
                </div>
              </div>
              <div className='book-rental-total-price'>
                ${thisCar.price + 16.64}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { BookPage }
