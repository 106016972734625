import React from 'react'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import carsData from '../data/carsData.json'
import { ReactComponent as Heart } from '../assets/img/svg/heart.svg'
import { ReactComponent as ReviewStar } from '../assets/img/svg/ReviewStar.svg'
import { CarCard } from '../components/CarCard'
function RentDetailPage() {
  const { productId } = useParams()
  const thisCar = carsData.find((prod) => String(prod.id) === productId)

  return (
    <>
      <div className='rent-detail'>
        <div className='container'>
          <div className='rent-detail__inner'>
            <div className='rent-detail-info'>
              <div className='rent-detail-info-gallery'>
                <img
                  src={require(`../assets/img/cars/${thisCar.img}`)}
                  alt={thisCar.name}
                />
                <img
                  src={require(`../assets/img/cars/${
                    thisCar.imgInside ? thisCar.imgInside : thisCar.img
                  }`)}
                  alt={thisCar.name}
                />
                <img
                  src={require(`../assets/img/cars/${
                    thisCar.imgBack ? thisCar.imgBack : thisCar.img
                  }`)}
                  alt={thisCar.name}
                />
              </div>
              <div className='rent-detail-info-panel'>
                <div className='rent-detail-info-panel-title'>
                  {thisCar.name}
                  <Heart />
                </div>
                <div className='rent-detail-info-panel-stars'>
                  <ReviewStar />
                  440+ Reviewer
                </div>
                <div className='rent-detail-info-panel-description'>
                  <p>{thisCar.description}</p>
                </div>
                <div className='rent-detail-info-panel-lists'>
                  <div className='rent-detail-info-panel-list'>
                    <img
                      src={
                        require('../assets/img/svg-car/people-orange.svg')
                          .default
                      }
                      alt='people orange'
                    />
                    {thisCar.people}
                  </div>
                  <div className='rent-detail-info-panel-list'>
                    <img
                      src={
                        require('../assets/img/svg-car/gas-station-orange.svg')
                          .default
                      }
                      alt='gas station orange'
                    />
                    {thisCar.fuelType}
                  </div>
                  <div className='rent-detail-info-panel-list'>
                    <img
                      src={
                        require('../assets/img/svg-car/car-orange.svg').default
                      }
                      alt='car orange'
                    />
                    {thisCar.bodyType}
                  </div>
                </div>
                <div className='rent-detail-info-panel-book'>
                  <div className='rent-detail-info-panel-book-title'>
                    Daily rate from
                  </div>
                  <div className='rent-detail-info-panel-book-price'>
                    ${thisCar.price}
                  </div>
                </div>
                <div className='rent-detail-info-panel-inputs'>
                  <div className='rent-detail-info-panel-inputs-item'>
                    <img
                      src={require('../assets/img/svg/calendar.svg').default}
                      alt=''
                    />
                    <input type='date' placeholder='dd-mm-yyyy' />
                  </div>
                  <div className='rent-detail-info-panel-inputs-item'>
                    <img
                      src={require('../assets/img/svg/calendar.svg').default}
                      alt=''
                    />
                    <input type='date' placeholder='dd-mm-yyyy' />
                  </div>
                  <div className='rent-detail-info-panel-inputs-item'>
                    <img
                      src={require('../assets/img/svg/clock.svg').default}
                      alt=''
                    />
                    <input type='time' />
                  </div>
                  <div className='rent-detail-info-panel-inputs-item'>
                    <img
                      src={require('../assets/img/svg/clock.svg').default}
                      alt=''
                    />
                    <input type='time' />
                  </div>
                </div>
                <NavLink
                  to={`/rent/${productId}/book`}
                  className='rent-detail-info-panel-btn'
                >
                  Book Now
                </NavLink>
              </div>
            </div>
            <div className='rent-detail-info-text'>
              <p>{thisCar.description2}</p>
              <span>Rent {thisCar.name}: our advantages</span>
              <p>{thisCar.advantages}</p>
              <span>Rent {thisCar.name} in our company is:</span>
              <ul>
                <li>- Guarantee of the best price on the market;</li>
                <li>
                  - Delivery to any convenient place (airport, hotel, etc.);
                </li>
                <li>- Transparency, no hidden fees;</li>
                <li>- Discounts for long-term rent;</li>
                <li>- Possibility to order a child seat in the salon.</li>
              </ul>
              <span>How to order {thisCar.name} rental? </span>
              <p>{thisCar.howOrder}</p>
              <span>For car hire you need: </span>
              <ul>
                <li>- Passport;</li>
                <li>- Driver's licence;</li>
                <li>- Card for deposit</li>
              </ul>
              <span>Main features of {thisCar.name}</span>
              <p>{thisCar.features}</p>
            </div>

            <div className='car-rec'>
              <h2 className='global-title'>Recomendation Car</h2>
              <div className='rent-list-cars-items'>
                {carsData.slice(0, 3).map((item) => {
                  return (
                    <CarCard
                      key={item.id}
                      img={item.img}
                      name={item.name}
                      people={item.people}
                      fuelType={item.fuelType}
                      bodyType={item.bodyType}
                      price={item.price}
                      page={item.id}
                    />
                  )
                })}
              </div>
            </div>

            <div className='car-rec'>
              <h2 className='global-title'>Recent Car</h2>
              <div className='rent-list-cars-items'>
                {carsData.slice(0, 3).map((item) => {
                  return (
                    <CarCard
                      key={item.id}
                      img={item.img}
                      name={item.name}
                      people={item.people}
                      fuelType={item.fuelType}
                      bodyType={item.bodyType}
                      price={item.price}
                      page={item.id}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { RentDetailPage }
