import React, { useState, useEffect } from 'react'
import authBg from '../assets/img/find-car-bg.jpg'
import { NavLink } from 'react-router-dom'
import { ReactComponent as ArrowRight } from '../assets/img/svg/arrow-right.svg'
import { CarCard } from '../components/CarCard'
import carsData from '../data/carsData.json'
function RentPage() {
  const [selectedFilters, setSelectedFilters] = useState([])
  const [filteredItems, setFilteredItems] = useState(carsData)

  let filters = [
    { name: 'SUV', img: 'suv' },
    { name: 'Electric Vehicles', img: 'electric-vehicles' },
    { name: 'Truck', img: 'truck' },
    { name: 'Minivans', img: 'minivans' },
    { name: 'Sport Coupe', img: 'sport-coupe' },
    { name: 'Convertible', img: 'convertible' },
  ]

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory)
      setSelectedFilters(filters)
    } else {
      setSelectedFilters([selectedCategory])
    }
  }

  useEffect(() => {
    filterItems()
  }, [selectedFilters])

  const filterItems = () => {
    if (selectedFilters.length > 0) {
      let tempItems = selectedFilters.map((selectedCategory) => {
        let temp = carsData.filter((item) => item.bodyType === selectedCategory)
        return temp
      })
      setFilteredItems(tempItems.flat())
    } else {
      setFilteredItems([...carsData])
    }
  }
  return (
    <>
      <div
        className='find-car rent'
        style={{ background: `url(${authBg}) center / cover no-repeat` }}
      >
        <div className='container'>
          <div className='find-car-filter'>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-text'>
                <label>Pick-up Location</label>
                <div className='inputbox'>
                  <input type='text' placeholder='Search a location' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-date'>
                <label>Pick-up date</label>
                <div className='inputbox'>
                  <input type='date' placeholder='dd-mm-yyyy' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-text'>
                <label>Drop-off Location</label>
                <div className='inputbox'>
                  <input type='text' placeholder='Search a location' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-date'>
                <label>Drop-off date</label>
                <div className='inputbox'>
                  <input type='date' placeholder='dd-mm-yyyy' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <NavLink to={'/rent'} className='find-car-filter-btn'>
                Find a Vehicle
                <ArrowRight />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='rent-card'>
        <div className='container'>
          <div className='rent-card__inner'>
            <div className='rent-card-box'>
              <div className='rent-card-box-items'>
                {filters.map((category, idx) => (
                  <button
                    onClick={() => handleFilterButtonClick(category.name)}
                    className={`rent-card-box-item ${
                      selectedFilters?.includes(category.name) ? 'active' : ''
                    }`}
                    key={`filters-${idx}`}
                  >
                    <img
                      src={require(`../assets/img/svg-body-type/${category.img}.svg`)}
                      alt=''
                    />
                    <span>{category.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='rent-list-cars'>
        <div className='container'>
          <div className='rent-list-cars__inner'>
            <div className='rent-list-cars-items'>
              {filteredItems.map((item) => {
                return (
                  <CarCard
                    key={item.id}
                    img={item.img}
                    name={item.name}
                    people={item.people}
                    fuelType={item.fuelType}
                    bodyType={item.bodyType}
                    price={item.price}
                    page={item.id}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { RentPage }
