import React from 'react'
import { NavLink } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ReactComponent as ArrowRight } from '../assets/img/svg/arrow-right.svg'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import authBg from '../assets/img/find-car-bg.jpg'
import porsche from '../assets/img/porsche.jpg'
import arrowRightBlack from '../assets/img/svg/arrow-right-black.svg'
import { CarCard } from '../components/CarCard'
import carsData from '../data/carsData.json'

import { TypeAnimation } from 'react-type-animation'
function HomePage() {
  return (
    <>
      <div
        className='find-car'
        style={{ background: `url(${authBg}) center / cover no-repeat` }}
      >
        <div className='container'>
          <div className='find-car__inner'>
            <div className='find-car__inner-box'>
              <h1 className='find-car-title'>
                <TypeAnimation
                  sequence={[
                    ' Enjoy your life with our comfortable cars.',
                    700,
                    '',
                    500,
                  ]}
                  repeat={Infinity}
                 
                />
              </h1>

              <div className='find-car-text'>
                Carent, is ready to serve the best experience in car rental.
              </div>
              <NavLink to='/rent' className='big-btn'>
                Explore Now
              </NavLink>
            </div>
          </div>
          <div className='find-car-filter'>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-text'>
                <label>Pick-up Location</label>
                <div className='inputbox'>
                  <input type='text' placeholder='Search a location' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-date'>
                <label>Pick-up date</label>
                <div className='inputbox'>
                  <input type='date' placeholder='dd-mm-yyyy' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-text'>
                <label>Drop-off Location</label>
                <div className='inputbox'>
                  <input type='text' placeholder='Search a location' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <div className='find-car-filter-input input-date'>
                <label>Drop-off date</label>
                <div className='inputbox'>
                  <input type='date' placeholder='dd-mm-yyyy' />
                </div>
              </div>
            </div>
            <div className='find-car-filter-item'>
              <NavLink to={'/rent'} className='find-car-filter-btn'>
                Find a Vehicle
                <ArrowRight />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className='rent-card'>
        <div className='container'>
          <div className='rent-card__inner hover'>
            <div className='rent-card-box'>
              <div className='rent-card-box-top'>
                <div className='rent-card-box-top-title'>Rent by Brands</div>
                <NavLink to={'/rent'} className='rent-card-box-top-link'>
                  View all
                  <img src={arrowRightBlack} alt='arrow' />
                </NavLink>
              </div>
              <div className='rent-card-box-items'>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/ToyotaIcon.svg').default
                    }
                    alt=''
                  />
                  <span>Toyota</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/AudiIcon.svg').default
                    }
                    alt=''
                  />
                  <span>Audi</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/TeslaIcon.svg').default
                    }
                    alt=''
                  />
                  <span>Tesla</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/VolkswagenIcon.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Volkswagen</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/BmwIcon.svg').default
                    }
                    alt=''
                  />
                  <span>BMW</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-brands/MercedesIcon.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Mercedes-Benz</span>
                </NavLink>
              </div>
            </div>
            <div className='rent-card-box'>
              <div className='rent-card-box-top'>
                <div className='rent-card-box-top-title'>Rent by body type</div>
                <NavLink to={'/rent'} className='rent-card-box-top-link'>
                  View all
                  <img src={arrowRightBlack} alt='arrow' />
                </NavLink>
              </div>
              <div className='rent-card-box-items'>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={require('../assets/img/svg-body-type/suv.svg').default}
                    alt=''
                  />
                  <span>SUV</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-body-type/electric-vehicles.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Electric vehicles</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-body-type/truck.svg').default
                    }
                    alt=''
                  />
                  <span>Truck</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-body-type/minivans.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Minivans</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-body-type/sport-coupe.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Sport Coupe</span>
                </NavLink>
                <NavLink to={'/rent'} className='rent-card-box-item'>
                  <img
                    src={
                      require('../assets/img/svg-body-type/convertible.svg')
                        .default
                    }
                    alt=''
                  />
                  <span>Convertible</span>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='how-works'>
        <div className='how-works-img'>
          <img src={require('../assets/img/bg-g-class.jpg')} alt='' />
        </div>
        <div className='container'>
          <div className='how-works__inner'>
            <div className='how-works-head'>
              <div className='how-works-title'>
                <h2 className='global-title'>How it woks</h2>
              </div>
              <div className='how-works-text'>
                <p>
                  Renting a luxury car has never been easier. Our streamlined
                  process makes it simple for you to book and confirm your
                  vehicle of choice online
                </p>
              </div>
            </div>
            <div className='how-works-items'>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={require('../assets/img/svg/search-lg.svg').default}
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Browse and select
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Choose from our wide range of premium cars, select the
                      pickup and return dates and locations that suit you best.
                    </p>
                  </div>
                </div>
              </div>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={
                      require('../assets/img/svg/calendar-check.svg').default
                    }
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Book and confirm
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Book your desired car with just a few clicks and receive
                      an instant confirmation via email or SMS.
                    </p>
                  </div>
                </div>
              </div>
              <div className='how-works-item'>
                <div className='how-works-item-icon'>
                  <img
                    src={require('../assets/img/svg/face-happy.svg').default}
                    alt='search'
                  />
                </div>
                <div className='how-works-item-con'>
                  <div className='how-works-item-con-title'>
                    Enjoy your ride
                  </div>
                  <div className='how-works-item-con-text '>
                    <p>
                      Pick up your car at the designated location and enjoy your
                      premium driving experience with our top-quality service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='car-slider'>
        <div className='container'>
          <div className='car-slider__inner'>
            <div className='car-slider-title'>
              <h2 className='global-title'>Our Featured Cars</h2>
            </div>
            <div className='car-slider-swiper'>
              <Swiper
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                slidesPerView={3}
                slidesPerGroup={3}
                speed={600}
                breakpoints={{
                  768: {
                    spaceBetween: 22,
                    slidesPerView: 3,
                  },
                  500: {
                    spaceBetween: 22,
                    slidesPerView: 2,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                  },
                }}
              >
                {carsData.slice(0, 9).map((item) => {
                  return (
                    <SwiperSlide key={item.id}>
                      <CarCard
                        img={item.img}
                        name={item.name}
                        people={item.people}
                        fuelType={item.fuelType}
                        bodyType={item.bodyType}
                        price={item.price}
                        page={item.id}
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </section>
      <section className='services'>
        <div className='container'>
          <div className='services__inner'>
            <div className='services__head'>
              <div className='services-title'>
                <h2 className='global-title'>Our Services & Benefits</h2>
              </div>
              <div className='services-text'>
                <p>
                  To make renting easy and hassle-free, we provide a variety of
                  services and advantages. We have you covered with a variety of
                  vehicles and flexible rental terms.
                </p>
              </div>
            </div>

            <div className='services-items'>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <img
                    src={require('../assets/img/svg/featured.svg').default}
                    alt='search'
                  />
                </div>
                <div className='services-item-title'>Quality Choice</div>
                <div className='services-item-text'>
                  We offer a wide range of high-quality vehicles to choose from,
                  including luxury cars, SUVs, vans, and more.
                </div>
              </div>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <img
                    src={require('../assets/img/svg/featured-2.svg').default}
                    alt='search'
                  />
                </div>
                <div className='services-item-title'>Affordable Prices</div>
                <div className='services-item-text'>
                  Our rental rates are highly competitive and affordable,
                  allowing our customers to enjoy their trips without breaking
                  the bank.
                </div>
              </div>
              <div className='services-item'>
                <div className='services-item-icon'>
                  <img
                    src={require('../assets/img/svg/featured-3.svg').default}
                    alt='search'
                  />
                </div>
                <div className='services-item-title'>
                  Convenient Online Booking
                </div>
                <div className='services-item-text'>
                  With our easy-to-use online booking system, customers can
                  quickly and conveniently reserve their rental car from
                  anywhere, anytime.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className='contact'
        style={{ background: `url(${porsche}) center / cover no-repeat` }}
      >
        <div className='container'>
          <div className='contact__inner'>
            <div className='contact-title'>
              <h2 className='global-title'>Contact Us</h2>
            </div>
            <div className='contact-box'>
              <form className='contact-box-form'>
                <input
                  className='custom-input'
                  type='text'
                  placeholder='Your Name*'
                />
                <input
                  className='custom-input'
                  type='text'
                  placeholder='Your Email*'
                />
                <textarea
                  className='custom-textarea'
                  placeholder='Your Message'
                ></textarea>
                <button className='big-btn' type='submit'>
                  Send A Message
                </button>
              </form>
              <div className='contact-box-img'>
                <img src={require('../assets/img/hand.jpg')} alt='search' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { HomePage }
