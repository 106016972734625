import React, { useState, useRef } from 'react'
import { useOutsideClick } from '../hooks/useOutsideClick'
import { NavLink } from 'react-router-dom'
import { ReactComponent as Logo } from '../assets/img/logo.svg'

/**
 * Here I have used the following approach for svg, so that it is possible to edit them
 */

function Header() {
  const [isActiveDropdown, setActiveDropdown] = useState(false)
  const [isActiveMenuBtn, setActiveMenuBtn] = useState(false)

  /**
   * Switch for hamburger menu
   */
  const handleToggleMenuBtn = () => {
    setActiveMenuBtn(!isActiveMenuBtn)
    document.body.classList.toggle('hiden')
  }

  /**
   * The function of closing elements when clicking on outside the element
   */
  const tooltipRef = useRef(null)
  const onCloseOutside = () => {
    setActiveDropdown(false)
  }
  useOutsideClick(tooltipRef, onCloseOutside, isActiveDropdown)

  return (
    <>
      <header className='header'>
        <div className='container'>
          <div className={`header__inner ${isActiveMenuBtn ? 'active' : ''}`}>
            <div className='header__inner-logo'>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </div>
            <nav
              className={`header__inner-nav ${isActiveMenuBtn ? 'active' : ''}`}
            >
              <ul className='nav-list'>
                <li className='nav-list-item'>
                  <NavLink to='/rent'>Rent</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/terms-of-service'>Terms of service</NavLink>
                </li>
                <li className='nav-list-item'>
                  <NavLink to='/contact'>Contact</NavLink>
                </li>
              </ul>
            </nav>

            <div className='header__inner-link'>
              <NavLink to='/'>Sign up</NavLink>
              <NavLink className={'login-btn'} to='/login'>
                Log in
              </NavLink>
            </div>
            <div
              className={`header__menu-btn ${isActiveMenuBtn ? 'open' : ''}`}
              onClick={handleToggleMenuBtn}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export { Header }
